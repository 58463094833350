<template>
  <div
    class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
          <v-row class="pa-3 mx-1">
            <v-col cols="12" sm="3">
              <v-card flat class="infoCards">
                <v-card-text>
                  <v-list dense subheader>
                <v-subheader style="font-size: 14px">Containers</v-subheader>
                <!-- {{containers}} -->
                <v-divider></v-divider>
                <v-list-item
                  v-for="container in containers"
                  :key="container.id"
                  @click="selectContainer(container)"
                  :style="{
                    'border-left':
                      selectedContainer && selectedContainer.id === container.id
                        ? '4px solid var(--v-primary-base)'
                        : '4px solid transparent',
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ container.containerNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ container.ctoNo }}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip v-if="!trackingLoading && tracking[container.id]" color="success" small> {{ tracking[container.id].length }} Tracking Sources</v-chip>
                    </v-list-item-action>   
                </v-list-item>
              </v-list>
                </v-card-text>
              </v-card>
             
            </v-col>
            <v-col cols="12" sm="9">
              <div >
                <ContainerTracking :container="selectedContainer" />
              </div>
            </v-col>
          </v-row>
  </div>
</template>
<script>
import ContainerTracking from "../Tracking/ContainerTracking.vue";
export default {
  name: "BookingContainerTracking",
  props: {
    booking: {
      type: Object,
      required: true,
    },
    containers: {
      type: Array,
      required: true,
    },
  },
  components: {
    ContainerTracking,
  },
  data: () => ({
    selectedContainer: {},
    tracking: {},
    trackingLoading: false,
  }),
  watch: {
    containers: {
      handler() {
        if (this.containers.length > 0) {
          this.selectedContainer = this.containers[0];
          this.retrieveContainerTracking(this.containers.map((c) => c.id));
        }
      },
      immediate: true,
    },
  },
  methods: {
    async retrieveContainerTracking(containerIds) {
        this.trackingLoading = true;
      this.tracking = await this.$API.listContainerTracking(containerIds);
      this.trackingLoading = false
    },
    selectContainer(container) {
      this.selectedContainer = container;
    },
  },
};
</script>

<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 95%;
  contain: content;
  overflow-y: auto;
}
.pulse-hazardous {
  animation: pulse-hazardous 1.5s infinite;
  z-index: 10;
}

@keyframes pulse-hazardous {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px #0e871e;
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}
</style>